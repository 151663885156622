(function () {
	"use strict";	

	require('./modules/config.js');
	require('./modules/ui.js');
	require('./modules/builder.js');
	require('./modules/utils.js');
	require('./modules/canvasElement.js');
	require('./modules/styleeditor.js');
	require('./modules/content.js');
	require('./modules/export.js');
	require('./modules/preview.js');

}());